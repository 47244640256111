import React from "react"
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout/Layout'

import { PageTitleHeader, Breakpoints, blissfulColour, blissfulColourDark } from '../globalStyles';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Carousel } from 'react-responsive-carousel';

import ogImage from "../../static/metadata/sample-box/sample-box.og.jpg";
import twitImage from "../../static/metadata/sample-box/sample-box.twitter.jpg";

const Container = styled.div`
  margin: 3rem auto;
  max-width: 1280px;
  padding: 0 20px;
`
const ImageContainer = styled.div`
  .thumbs-wrapper {
    margin: 0 !important;
    margin-bottom: -20px !important;
  }
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    width: 60%;
  }
`
const ImageThumbails = styled.div`
  width: 80px;
  height: 50px;
`
const Price = styled.h4`
`

const ProductContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    display: flex;
    div {
      margin: 5px;
    }
  }
`

const ProductInformationContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    width: 40%;
    div {
      margin-bottom: 20px;
    }
  }
`

const ProductDescription = styled.div`
`

const ImageSliderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`

export const queryMobile = graphql`
  query {
    sampleBoxImages: allFile(
      filter: {
        sourceInstanceName: {eq: "images"}, 
        relativeDirectory: {eq: "sample-box"}
      },
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              quality: 90
              formats: WEBP
            )
          }
        }
      }
    }
  }
`

const SampleBox = ({ data }) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return (    
    <Layout
      title="Luxurious Sample Box"
      description="A luxurious cake sample box containing 5 delectable flavours. Each cake slice is individually hand wrapped for freshness. Each box includes a menu card of all our cake flavour options."
      pathname={pathname}
      ogImage={ogImage}
      twitImage={twitImage}
    >
      <Container>

        <ProductContainer>

          <ImageContainer>
            <Carousel
              autoPlay={false}
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              swipeable={false}
              dynamicHeight={true}
              showThumbs={false}
              // renderThumbs={renderCustomThumbs}
              animationHandler="fade"
            >
              { data.sampleBoxImages.edges.map(image => (
                <ImageSliderContainer key={image.node.id}>
                  <GatsbyImage
                    image={image.node.childImageSharp.gatsbyImageData}
                    alt={image.node.name}
                    objectFit="cover"
                  />
                  { image.node.id }
                </ImageSliderContainer>
              )) }
            </Carousel>
          </ImageContainer>

          <ProductInformationContainer>

            <PageTitleHeader>Luxurious Sample Box</PageTitleHeader>

            <Price> $35 </Price>

            <ProductDescription>
              <p> A <i>luxurious cake sample box containing 5 delectable flavours.</i> Each cake slice is individually hand wrapped for freshness. Each box includes a menu card of all our cake flavour options. </p>
              <p>Whether you fancy a sweet treat to brighten your day, a birthday gift or your wedding is around the corner, try out some of our delicious flavours or send these as a surprise cake gift to your loved one! These boxes are open to everyone!</p>
              <p>Produced multiple times a year on a set date, the cake sample box will contain a random selection of cake slices from the Blissful Crumbs menu. The cake flavour options will slightly change for every box and will contain the best seasonal or best selling flavours.</p>
              <p>This sample box will include the following of our many flavours:</p>
              <ol>
                <li>Creamy Lotus Biscoff </li>
                <li>Creamy Salted Caramel</li>
                <li>Creamy Strawberry Shortcake</li>
                <li>Creamy Ferrero </li>
                <li>Creamy Chocolate Salted Caramel </li>                
              </ol>

              <p>This gives you the perfect opportunity to try out some of our popular flavours and make the best decision for your cake!</p>

              <p><b>Allergens: eggs, milk, dairy, gluten</b></p>
              <p><b>Made in an environment that contains nuts and gluten</b></p>
              <p><b>Shelf life & storage:</b> Do not refrigerate unless you won’t be consuming them on the day of pickup. Take the cakes out of the fridge 2 hours before indulging to ensure they have gotten back to room temperature. 
                    Our cakes and buttercream are made using real butter which will result in the cakes being hard and dry unless they are brought back to room temp to re-soften.  </p>
            </ProductDescription>

          </ProductInformationContainer>

        </ProductContainer>
      </Container>
    </Layout>
  )
}

export default SampleBox


const renderCustomThumbs = (children) =>
  children.map((item) => {
  return (
    <ImageThumbails>
      <img src={item.props.children[0].props.image.images.fallback.src} />
    </ImageThumbails>     
  )
});